import request from "@/utils/request";
import * as md5 from 'js-md5'

export function sendResetPasswordSms(mobile) {
  return request({
    url: `/seller/passport/sms-code`,
    method: 'post',
    noVerificationToken: true,
    data: {
      mobile,
    },
  });
}

export function resetPassword({password, sms_code, mobile}) {
  password = md5(password);
  return request({
    url: '/seller/passport/find-pwd/update-password',
    method: 'put',
    noVerificationToken: true,
    data: {
      password,
      sms_code,
      mobile,
    }
  });
}
