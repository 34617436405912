<template>
  <div class="page-reset-password">
    <el-card class="login-card" :class="{smaller: isHideDecorate}">
      <div class="row no-gutters text-center">
        <div class="col" v-if="!isHideDecorate">
          <img class="decorate" src="/img/login-decorate.png"/>
        </div>
        <div class="col pb-5 pl-3 pr-3">
          <div class="logo">
            <img :src="navbar.logo || '/img/logo.png'" alt="">
          </div>
          <div class="app-name">重置密码</div>
          <div class="app-name-en" style="text-transform: uppercase">reset password</div>

          <el-form :model="form" :rules="formRules" ref="form">
            <el-tabs v-model="currentTabIndex">
              <el-tab-pane name="0">
                <el-form-item>
                  <el-input
                    placeholder="请输入手机号"
                    v-model="form.mobile"
                    maxlength="11"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-user"></i>
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-input
                    placeholder="请输入验证码"
                    v-model="form.sms_code"
                    maxlength="6"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-chat-dot-round"></i>
                    <el-button slot="suffix" type="primary" style="transform: translateX(4px)" @click="sendSms"
                               :disabled="!!countDownText">
                      {{ countDownText || '获取验证码' }}
                    </el-button>
                  </el-input>
                </el-form-item>
              </el-tab-pane>

              <el-tab-pane name="1">
                <el-form-item prop="password">
                  <el-input
                    placeholder="请输入新密码"
                    v-model="form.password"
                    type="password"
                    maxlength="40"
                    autocomplete="new-password"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  </el-input>
                </el-form-item>

                <el-form-item prop="password_confirm">
                  <el-input
                    placeholder="请确认新密码"
                    v-model="form.password_confirm"
                    type="password"
                    maxlength="40"
                    autocomplete="new-password"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  </el-input>
                </el-form-item>
              </el-tab-pane>
            </el-tabs>

          </el-form>

          <el-button type="primary" class="w-100 mt-2" @click="nextStep">
            {{ currentTabIndex === '0' ? '下一步' : '确认修改' }}
          </el-button>

          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <router-link :to="{name: '登录'}">
                <el-button type="text">去登陆</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <div class="copyright">
    </div>
  </div>
</template>

<script lang="js">
import Storage from '@/utils/storage'
import {v1} from 'uuid'
import {$platform} from '@/libs/platform/platform';
import {$layout} from '@/libs/layout/layout.service';
import {mobile} from "@/libs/common/utils/RegExp";
import {resetPassword, sendResetPasswordSms} from "@/pages/$auth/reset-password/reset-password.api";

export default {
  name: 'login',
  data() {
    return {
      resizeListener: null,
      isHideDecorate: false,
      navbar: $layout.navbar,
      currentTabIndex: '0',
      form: {
        mobile: '',
        password: '',
        password_confirm: '',
        sms_code: '',
      },
      captcha: '',
      countDownText: '',
      uuid: Storage.getItem('seller_uuid'),
      // 校验规则
      formRules: {
        password: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          }
        ],
        password_confirm: [
          {
            required: true,
            message: '请确认新密码',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  mounted() {
    const uuid = Storage.getItem('seller_uuid')
    if (uuid) {
      this.uuid = uuid
    } else {
      const _uuid = v1()
      this.uuid = _uuid
      Storage.setItem('seller_uuid', _uuid, {expires: 30})
    }
    this.resizeListener = $platform.onWindowResize.subscribe(size => {
      this.isHideDecorate = size.width < 1200;
    });
  },
  destroyed() {
    this.resizeListener.unsubscribe();
  },
  methods: {
    /** 翻译 */
    nextStep() {
      if (this.currentTabIndex === '0') {
        if (!this.form.mobile) {
          return this.$message.error('请输入手机号');
        }

        if (!this.form.sms_code) {
          return this.$message.error('请输入验证码');
        }

        this.currentTabIndex = '1';
      } else {
        this.submitLogin();
      }
    },
    /** 更换图片验证码 */
    sendSms() {
      if (this.countDownText) {
        return;
      }

      if (!this.form.mobile || !mobile.test(this.form.mobile)) {
        return this.$message.error('请填写正确的手机号');
      }

      sendResetPasswordSms(this.form.mobile).then(resp => {
        this.$message.success('短信验证码发送成功，请注意查收');
        this.startCountdown();
      });
    },
    startCountdown() {
      let s = 60;
      this.countDownText = `${s--}秒后重新获取`;

      const id = setInterval(() => {
        this.countDownText = `${s--}秒后重新获取`;
        if (s === 0) {
          this.countDownText = '';
          clearInterval(id);
        }
      }, 1000);
    },
    /** 表单提交 */
    submitLogin() {
      if (!this.form.password) {
        return this.$message.error('请输入新密码');
      }

      if (!this.form.password_confirm) {
        return this.$message.error('请确认新密码');
      }

      const params = this.MixinClone(this.form);
      params.uuid = this.uuid
      resetPassword(this.form).then(resp => {
        this.$message.success('密码重置成功');
        this.$router.push({name: '登录'});
      });
    }
  }
}
</script>

<style lang="scss">
@import "./reset-password";
</style>
